import React from "react";

function Banner() {
  return (
    <div className="banner">
      <h1 style={{ marginBottom: "0px" }}>Etiquette Pharmaceuticals Limited</h1>
      <p>Nigeria-based wholesaler of pharmaceutical and healthcare products.</p>
    </div>
  );
}

export default Banner;
