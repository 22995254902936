import React from "react";

function Footer() {
  return (
    <div className="footer">
      <div className="f1">
        <p style={{ marginBottom: "0px" }}>Registered in Nigeria: RC 1727250</p>
        <p style={{ marginTop: "5px" }}>
          Registered Address: House 12-14, S. Zakari Close, NAF Valley Estate,
          Asokoro, Abuja
        </p>
      </div>
      <div className="f2">
        <div className="f21">
          <p>
            Copyright © 2023 Etiquette Pharmaceuticals Limited. All Rights
            Reserved
          </p>
        </div>
        <div className="f22">
          <p>
            website by{" "}
            <a href="https://algorizimcodes.vercel.app/">
              algorizim
              <span style={{ fontWeight: "bolder", color: "black" }}>
                Codes
              </span>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
