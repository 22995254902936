import React, { useState } from "react";

import Banner from "../components/Banner";
import Form from "../components/Form";
import Footer from "../components/Footer";
import LOGO from "../data/EPLOGO.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

import { HashLink } from "react-router-hash-link";

function Home() {
  const [shrink, setShrink] = useState(false);
  const [showLogo, setShowLogo] = useState(false);
  const [width1, setWidth1] = useState("30%");
  const [width2, setWidth2] = useState("65%");

  function resizeHeaderOnScroll() {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop,
      shrinkOn = 150;
    const screenWidth = window.innerWidth;

    if (distanceY > shrinkOn && screenWidth > 900) {
      setShrink(true);
      setWidth1("20%");
      setWidth2("70%");
    } else if (distanceY > shrinkOn && screenWidth < 900) {
      setShowLogo(true);
    } else {
      setShrink(false);
      setShowLogo(false);
      setWidth1("30%");
      setWidth2("65%");
    }
  }

  window.addEventListener("scroll", resizeHeaderOnScroll);

  return (
    <div>
      <div className="header">
        {showLogo ? (
          <div className="logo">
            <img src={LOGO} alt="LOGO" width={30} /><small>Limited</small>
          </div>
        ) : (
          <div className="title" style={{ width: width1 }}>
            <h1
              style={{
                marginBottom: "0px",
                fontSize: shrink ? "1.2rem" : "2rem",
              }}
            >
              Etiquette Pharmaceuticals
            </h1>
            <h2
              style={{
                marginTop: "0px",
                fontSize: shrink ? "0.8rem" : "1.5rem",
              }}
            >
              Limited
            </h2>
          </div>
        )}

        <div className="contact-us" style={{ width: width2 }}>
          <HashLink smooth to="#contact">
            <FontAwesomeIcon
              icon={faPhone}
              color="blue"
              style={{ margin: "0px 0.2rem" }}
            />
            CONTACT US
          </HashLink>
        </div>
      </div>
      <div className="body">
        <Banner />
        <div className="info">
          <h2>
            We supply Generics, Parallel Imports, Branded products and Over the
            Counter products.
          </h2>
          <p>
            Etiquette Pharmaceuticals Limited is licensed by the Pharmacists
            Council of Nigeria (PCN).
          </p>
        </div>
        <div className="form-container" id="contact">
          <Form />
          <div className="img-cont">
            <div className="img"></div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Home;
