import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

import Emailjs from "./Emailjs";

function Form() {
  return (
    <div className="form-body">
      <div className="form-heading">
        <p style={{ fontSize: "x-large" }}>CONTACT US</p>
        <h4>Etiquette Pharmaceuticals Limited</h4>
        <h4 style={{ marginBottom: "0px" }}>
          <FontAwesomeIcon icon={faPhone} /> +2348061298497
        </h4>
        <h4 style={{ marginTop: "0px" }}>
          <FontAwesomeIcon icon={faEnvelope} />{" "}
          info@etiquettepharmaceuticals.com
        </h4>
        <p>SEND AN ENQUIRY</p>
      </div>
      <Emailjs />
    </div>
  );
}

export default Form;
